import { query } from '@solidjs/router';
import { getIsolationScope } from '@sentry/solidstart';
import { getApiClient } from './get-api-client';
import { ttlCache } from './cache';
import type { CachedFunction } from '@solidjs/router';
import type { AnyVariables, CombinedError, OperationResult, TypedDocumentNode } from '@urql/core';
import type { OperationDefinitionNode } from 'graphql';

type Options<Data = unknown, Variables extends AnyVariables = AnyVariables> = {
	onData?: (data?: OperationResult<Data, Variables | Record<string, string>>['data']) => unknown;
	onError?: (error: CombinedError) => void;
	retry?: {
		retryIf?: (result: OperationResult<Data, Variables>) => boolean;
		maxAttempts?: number;
		delay?: number;
	};
};

export function cachedQuery<Data = unknown, Variables extends AnyVariables = AnyVariables>(
	document: TypedDocumentNode<Data, Variables>,
	options: Options<Data, Variables> = {},
): CachedFunction<
	(variables: Variables) => Promise<OperationResult<Data, Variables | Record<string, string>>['data']>
> {
	const cacheKey =
		(document.definitions.find((def) => def.kind === 'OperationDefinition') as OperationDefinitionNode)?.name?.value ??
		document.toString();
	const cacheFn = query(
		async (variables: Variables): Promise<OperationResult<Data, Variables | Record<string, string>>['data']> => {
			const cachedData: Data | undefined = ttlCache?.get<Data>(cacheFn.keyFor(variables));
			if (cachedData) {
				return cachedData;
			}
			let handled = false;

			try {
				let res: OperationResult<Data, Variables> | undefined = undefined;
				const maxAttempts = !options?.retry ? 3 : (options.retry?.maxAttempts ?? 3);
				const retryIf = options?.retry?.retryIf ?? (() => false);
				for (let i = 0; i < maxAttempts; i += 1) {
					res = await getApiClient().query(document, variables);
					if (!retryIf(res!) && !defaultRetry(res!)) {
						break;
					}
					await new Promise<void>((resolve) => {
						setTimeout(() => {
							resolve();
						}, options?.retry?.delay ?? 750);
					});
				}

				if (res! && res.error) {
					if (options.onError) {
						handled = true;
						options.onError(res.error);
						return;
					}

					throw res.error;
				}

				ttlCache?.set(cacheFn.keyFor(variables), res!.data);

				return res!.data;
			} catch (e) {
				if (!handled) {
					const scope = getIsolationScope();
					scope.setExtra('cacheKey', cacheKey);
					scope.captureException(e);
				}
				throw e;
			}
		},
		cacheKey,
	);

	return cacheFn;
}

function defaultRetry(res: OperationResult<unknown, AnyVariables>) {
	return res.error?.message.includes('Load failed') || res.error?.message.includes('Failed to fetch');
}
